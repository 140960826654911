import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout/Layout";
import { HeroSection, useRoadmapApi, RoadmapSection, RoadmapConsentCheckbox, RoadmapHoneypotInput, RoadmapSubmitButton, refineFormData } from "./roadmap-template";

import { pushSAEvent } from "../helpers";

export default ({
    data,
    location,
    pageContext
}) => {
    pushSAEvent('visit_report_bug');

    return (
        <Layout
            title="Report a bug"
            description="Report a bug found in Frends iPaas."
            transparentNavigation={true}
            isDark={true}
            pageContentId="roadmap-page-content"
            location={location}
        >
            <main
                id="roadmap-page-content"
                style={{ marginTop: "-80px" }}
            >

                <HeroSection bg={data.bg}
                    title="Found a bug?"
                >
                    <p className="size-20 is-black">Please let us know, so we can fix it!</p>
                </HeroSection>

                <ReportBugSection frendsVersions={pageContext.frendsVersions} />
            </main>
        </Layout>
    );
};

const ReportBugSection = ({ frendsVersions }) => {
    const { postDataToRoadmapApi, roadmapApiFetching } = useRoadmapApi();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleRequestFeatureFormSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const Email = formData.get('email');
        const Summary = refineFormData(formData.get('summary'));
        const FrendsVersion = formData.get('frends_version');
        const Description = refineFormData(formData.get('description'));
        const StepsToReproduce = refineFormData(formData.get('steps_to_reproduce'));
        const Severity = formData.get('severity');
        const Honeypot = formData.get('contact_me_by_fax_only');

        postDataToRoadmapApi('bugs', {
            Email,
            Summary,
            FrendsVersion,
            Description,
            StepsToReproduce,
            Severity,
            Honeypot,
        });
    };

    useEffect(() => {
        if (roadmapApiFetching === false && typeof window !== 'undefined') {
            setFormSubmitted(true);
            window.scrollTo(0, 0);
        }
    }, [roadmapApiFetching]);

    const severityOptions = ['Lowest', 'Low', 'Medium', 'High', 'Highest'];

    return (
        <RoadmapSection
            id="request-feature"
            title="Report a bug"
        >
            {!formSubmitted ? (
                <>
                    <form
                        className="request-for-feature-form"
                        onSubmit={handleRequestFeatureFormSubmit}
                    >
                        <label>
                            Your email
                            <input
                                name="email"
                                type="email"
                                minLength="1"
                                maxLength="256"
                                required
                                disabled={roadmapApiFetching}
                            />
                        </label>

                        <label>
                            Summary
                            <input
                                name="summary"
                                type="text"
                                minLength="1"
                                maxLength="256"
                                required
                                disabled={roadmapApiFetching}
                                autoComplete="off"
                            />
                        </label>

                        <label>
                            Frends version
                            <div className="select-container">
                                <select
                                    name="frends_version"
                                    required
                                    defaultValue=""
                                >
                                    <option
                                        value=""
                                        disabled
                                        hidden
                                    >
                                        Choose version
                                    </option>
                                    {frendsVersions.map((v, i) => (
                                        <option key={i} value={v.Id}>
                                            {v.Name}
                                        </option>
                                    ))}
                                </select>

                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                        </label>

                        <label>
                            Description
                            <textarea
                                name="description"
                                rows="10"
                                minLength="1"
                                maxLength="8192"
                                autoComplete="off"
                                required
                                disabled={roadmapApiFetching}
                            />
                        </label>

                        <label>
                            Steps to reproduce
                            <textarea
                                name="steps_to_reproduce"
                                rows="10"
                                minLength="1"
                                maxLength="8192"
                                autoComplete="off"
                                required
                                disabled={roadmapApiFetching}
                            />
                        </label>

                        <label>
                            Severity
                            <div className="select-container">
                                <select
                                    name="severity"
                                    required
                                    defaultValue=""
                                >
                                    <option
                                        value=""
                                        disabled
                                        hidden
                                    >
                                        Choose severity
                                    </option>

                                    {severityOptions.map((o, i) => (
                                        <option key={i} value={o}>
                                            {o}
                                        </option>
                                    ))}
                                </select>

                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </div>
                        </label>

                        <RoadmapConsentCheckbox
                            label="By sending this bug report you agree to be sent emails regarding the progress of the subject and inquiries for additional information."
                        />

                        <RoadmapHoneypotInput />

                        <RoadmapSubmitButton loading={roadmapApiFetching} />
                    </form>

                    <p className="size-18 is-black mt-5">
                        Want to request a feature? You can do it <Link to="/roadmap#request-feature" className="is-purple">here</Link>.
                    </p>
                </>

            ) : (
                <p className="size-24 is-black thank-you-paragraph">
                    Thank you for your interest towards Frends!
                </p>
            )}
        </RoadmapSection>
    );
};

export const query = graphql`query {
    bg: file(relativePath: {eq: "images/pricing_bg.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
}`;